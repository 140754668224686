import { commonConfig } from '../common';
import ensighten from '../../config/common/ensighten';

const env = 'prod';

const config = {
  ...commonConfig,
  appEnv: env,
  ensighten: ensighten.getConfig(env),
  launchDarklyClientSideID: '65f38f75397645102ff117de', // PROD client-side ID
  digitalPaymentsApiBaseUrl:
    'https://api.suncorp.com.au/digital-payments-xapi/api/v1',
  cardCaptureComponentUrl:
    'https://secureccc.suncorp.com.au/card-capture-component',
};

export default window.envConfig = Object.freeze(config);
