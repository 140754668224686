// Common dataLayer config
import { SG_BRAND_NAME } from '../../utils/constants/commonConstants';

const dataLayerBrandConfigs = {
  apia: {
    brand: 'apa',
    siteID: 'in:apa',
    rsID: 'sunapia',
    metricsServer: 'apia.com.au',
  },
  aami: {
    brand: 'ami',
    siteID: 'in:ami',
    rsID: 'sunaami',
    metricsServer: 'aami.com.au',
  },
  gio: {
    brand: 'gio',
    siteID: 'in:gio',
    rsID: 'sungio',
    metricsServer: 'gio.com.au',
  },
  shannons: {
    brand: 'shn',
    siteID: 'in:shn',
    rsID: 'sunshannons',
    metricsServer: 'shannons.com.au',
  },
  suncorp: {
    brand: 'sun',
    siteID: 'in:sun',
    rsID: 'sun',
    metricsServer: 'suncorp.com.au',
  },
  bingle: {
    brand: 'bin',
    siteID: 'in:bin',
    rsID: 'sunbingle',
    metricsServer: 'bingle.com.au',
  },
  cil: {
    brand: 'cil',
    siteID: 'in:cil',
    rsID: 'suncil',
    metricsServer: 'cilinsurance.com.au',
  },
  vero: {
    brand: 'ver',
    siteID: 'in:ver',
    rsID: 'sunvero',
    metricsServer: 'vero.com.au',
  },
};

const dataLayer = {
  events: [],
};

const getEnsightenBootstrapUrl = (brand, env) =>
  `https://assets1.${SG_BRAND_NAME}.com.au/suncorp/${brand}-payonline-${env}/Bootstrap.js`;

const mapEnv = {
  local: 'stage',
  dev: 'stage',
  test1: 'stage',
  stable1: 'stage',
  beta: 'prod',
  prod: 'prod',
};

const mapBrand = {
  aami: 'aami',
  apia: 'apia',
  suncorp: 'sun',
  gio: 'gio',
  shannons: 'shn',
  bingle: 'bin',
  cil: 'cil',
  vero: 'ver',
};

// Generate the config by dynamic env
const getConfig = (env) => {
  const mappedEnv = mapEnv[env];
  const mappedBrand = mapBrand[SG_BRAND_NAME];
  return {
    dataLayer: Object.assign(dataLayer, {
      ...dataLayerBrandConfigs[SG_BRAND_NAME],
      brand: SG_BRAND_NAME,
      channel: 'web',
      currentYear: new Date().getFullYear().toString(),
      dataModel: {
        dataModelType: 'payonline',
        payonline: {
          selfServiceType: 'in_selfservice_payment_online',
        },
      },
      campaign: {
        campaignID: null,
        internalCampaignID: null,
        matchType: null,
        keyword: null,
      },
      environment: mappedEnv,
      events: [],
      errors: [],
      serialisationDatetime: Date.now().toString(),
      version: '1.0',
    }),
    bootstrapUrl: getEnsightenBootstrapUrl(mappedBrand, mappedEnv),
  };
};

// Whitelist sites
const contentSecurityPolicyList = [
  'nexus.ensighten.com',
  'www.googleadservices.com',
  'www.facebook/com/tr',
  'ps.eyeota.net',
  'pixel.everesttech.net',
  'smetrics.aami.aom.au',
  'clicktalecdn.sslcs.cdngc.net',
  'googleads.g.doubleclick.net',
  '*.fls.doubleclick.net/',
  'siteintercept.qualtrics.com',
  'cm.everesttech.net',
  'www.google.com',
  'rtd.tubemogul.com',
];

const ensighten = {
  dataLayer,
  getConfig,
  contentSecurityPolicyList,
};

export default ensighten;
